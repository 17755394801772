<template>
  <div class="bgcard" ref="bgcard">
    <div>
      <div class="title">
        <div class="lines"></div>
        <p>{{ $t("lang.Workorderdetails") }}</p>
      </div>
      <div v-for="(item, index) in workdetails.description" :key="index">
					<div class="msg" v-if="item.category == 2">
					  <img v-if="item.img" :src="item.img" />
					  <img v-else src="../../../../assets/imgs/yhzcl.png" />
					  <div class="msgcontent">
					    <p>
								<span>{{ item.name ? item.name : item.email }}</span>
								<span>{{ item.time }}</span>
					    </p>
					    <div class="msgcard">{{ item.description }}</div>
					  </div>
					</div>
					<div v-if="item.category == 2 && item.file">
					  <div class="msg" v-for="(items, indexs) in item.file" :key="'a_' + indexs">
					    <img v-if="item.img" :src="item.img" />
					    <img v-else src="../../../../assets/imgs/yhzcl.png" />
					    <div class="msgcontent img">
					      <p>
					        <span>{{ item.name ? item.name : workdetails.email }}</span><span>{{ item.time }}</span>
					      </p>
					      <div class="msgcard">
					        <img :src="items" @click="seeimg(items,index,indexs)" :ref="'imgs_'+index+'s_'+indexs" />
					      </div>
					    </div>
					  </div>
					</div>
					<div class="msg customer" v-if="item.category == 1">
					  <div class="msgcontent">
					    <p>
					      <span>{{ item.time }}</span
					      ><span>{{
					        item.name ? item.name : item.email
					      }}</span
					      >
					    </p>
					    <div class="msgcard" style="background:#307B37;color:#ffffff;">{{ item.description }}</div>
					  </div>
					  <img :src="user.img" />
					</div>
					<div class="customer" v-if="item.category == 1 && item.file">
						<div class="msg customer" v-for="(items, indexs) in item.file" :key="'a_' + indexs">
							<div class="msgcontent img">
								<p>
									<span>{{ item.time }}</span>
									<span>{{ workdetails.name ? workdetails.name : workdetails.email}}</span>
								</p>
								<div class="msgcard">
									<img :src="items" @click="seeimg(items,index,indexs)" :ref="'imgs_'+index+'s_'+indexs" />
								</div>
							</div>
							<img :src="user.img" />
						</div>
					</div>
      </div>
    </div>
    <div v-if="workdetails.state != 2">
      <div class="line">
        <div class="lines"></div>
        <p>{{ $t("lang.reply") }}</p>
        <div class="lines"></div>
      </div>
      <div class="question">
        <div class="describe">
          <p>{{ $t("lang.Problemdescription") }}:</p>
          <textarea
            v-model="problemtext"
            maxlength="256"
            :placeholder="$t('lang.Pleaseinputthecontent')+'（'+$t('lang.Upcharacters')+'）'"
          ></textarea>
        </div>
        <div class="enclosure">
          <p>{{ $t("lang.enclosure") }}:</p>
          <el-upload
            class="upload-demo"
            :action="url"
            :before-remove="beforeRemove"
            :limit="3"
            :headers="headers"
            list-type="picture"
            :on-exceed=" () => { $message.error($t('lang.Themaximumnumber'));}"
            :file-list="fileList"
            :on-success="success"
            accept=".jpg,.jpeg,.png"
            :before-upload="beforeUpload"
						 :on-remove='imgremove'
          >
            <el-button size="small" type="primary" style="font-size: 16">+ {{ $t("lang.Addfile") }}</el-button>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="btn">
      <button @click="subWorkMsg" v-if="workdetails.state != 2">
        {{ $t("lang.submit") }}
      </button>
      <button @click="retuen">{{ $t("lang.return") }}</button>
    </div>
		<div class="bigimg">
			<el-dialog :visible.sync="detailsmsg" top="3rem" :width="wids" :show-close="false">
			   <div class="bigimgsa">
					 <img :src="bigimgs" />
				 </div>
			</el-dialog>
		</div>
  </div>
</template>

<script>
import * as workDetails from "@/api/apis";
import * as work from "@/api/manage.js";
import * as baseURL from "@/utils/request.js";
export default {
  data() {
    return {
      fileList: [],
      imgurl: "",
      workdetails: [],
      url: "",
      tokens: "",
      problemtext: "",
      file: [],
	    user:"",
			filessa:true,
			detailsmsg:false,
			bigimgs:'',
			wids:''
    };
  },
  mounted() {
    this.url = baseURL.upload;
    this.imgurl = baseURL.imgUrl;
    let accessToken = sessionStorage.getItem("token");
	  let user = sessionStorage.getItem("user");
    this.tokens = JSON.parse(accessToken);
	  this.user = JSON.parse(user);
    this.getdeitge();
  },
  computed: {
    headers() {
      return {
        Authorization: this.tokens, // 直接从本地获取token就行
      };
    },
  },
  methods: {
    //获取工单详情
    getdeitge() {
      workDetails.byWorkOrder({ id: this.$route.query.id }).then((res) => {
        this.workdetails = res.data;
      });
    },
    //上传成功之后
    success(res) {
      if (res) {
        this.$message.success(this.$t("lang.Uploadsucceeded"));
        this.file.push(res.data.path);
      }
    },
    //删除文件之前
    beforeRemove(file) {
			if(this.filessa){
				return this.$confirm(this.$t("lang.Areyousureyouwanttoremove")+`${file.name}？`);
			}
    },
		//删除文件
		imgremove(file){
			if(this.filessa){
				let sk=this.file.indexOf(file.response.data.path)
				this.file.splice(sk,1)
			}
		},
    //上传文件之前
    beforeUpload(file) {
			this.filessa=file
      let isIMG =
        file.name.split(".")[file.name.split(".").length - 1] === "jpg" ||
        file.name.split(".")[file.name.split(".").length - 1] === "jpeg" ||
        file.name.split(".")[file.name.split(".").length - 1] === "png";
				const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isIMG) {
        this.$message.error(
          this.$t("lang.Uploadavatarimagecanonlybe") +
            "JPG、PNG、JPEG" +
            this.$t("lang.format")
        );
				this.filessa=false
				return this.filessa
      }
    },
    //提交
    subWorkMsg() {
      // if (this.problemtext == "") {
      //   this.$message.error(
      //     this.$t("lang.Pleasecompletetheworkorderinformation")
      //   );
      //   return false;
      // }
      let workmsg = {
        description: {
          description: this.problemtext,
        },
        id: this.$route.query.id,
        type: this.workdetails.type,
        userID: this.workdetails.userID,
        email: this.workdetails.email,
        phone: this.workdetails.phone,
      };
      if (this.file.length > 0) {
        workmsg.description.file = this.file;
      }
      // console.log(workmsg);
      work.workUpload(workmsg).then((res) => {
        this.$message.success(this.$t("lang.Submitted"));
        this.problemtext = "";
        this.file = [];
        this.fileList = [];
        this.getdeitge();
      });
    },
		seeimg(img,ind,inds){
			this.detailsmsg=true
			this.bigimgs=img
			let sk='imgs_'+ind+'s_'+inds
			let ims=this.$refs[sk]
			this.wids=ims[0].naturalWidth+'px'
			if(ims[0].naturalWidth>1400){
				this.wids=14+'rem'
			}
			if(ims[0].naturalWidth<200){
				this.wids=2+'rem'
			}
		},
    //返回
    retuen() {
      this.$router.push({
        path: "workorder",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  width: 100%;
  border: 0px;
  box-sizing: border-box;
  padding: .19rem .17rem;
  border-radius: 2px;
  background: #222628;
  padding: .39rem .34rem .70rem .38rem;
  border-radius: 2px;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;
  .lines {
    width: 4px;
    height: .30rem;
    background: #b2b2b2;
    border-radius: 2px;
    margin-right: .11rem;
  }
  p {
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
  }
}
.msg {
  display: flex;
  margin-top: .29rem;
  img {
    width: .45rem;
    height: .45rem;
    border-radius: 50%;
    margin-right: .09rem;
  }
  .msgcontent {
    width: 5.07rem;
    p {
      display: flex;
      justify-content: space-between;
      margin-top: 4px;
      margin-bottom: .1rem;
      span {
        font-size: .14rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffff;
      }
    }
    .msgcard {
      background: #161a1c;
      border-radius: 4px;
      padding: .16rem .15rem;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: .2rem;
      color: #b2b2b2;
      word-break: break-all;
    }
  }
  .img {
    width: 3.5rem;
    border-radius: 0;
    .msgcard {
      padding: .16rem .15rem;
      box-sizing: border-box;
      img {
        width: 3.2rem;
        height: auto;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
.customer {
  margin-top: .29rem;
  justify-content: flex-end;
  img {
    margin-right: 0px;
    margin-left: .09rem;
  }
}
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .36rem;
  .lines {
    width: 6.80rem;
    height: 1px;
    background: #4c4c4c;
  }
  p {
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    margin: 0px .13rem;
  }
}
.question {
  margin-top: .36rem;
  p {
    font-size: .16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    margin-top: .22rem;
    margin-right: .16rem;
  }
  .describe {
    // display: flex;
    margin-bottom: .1rem;
    textarea {
      width: 6.56rem;
      height: 1rem;
      outline-style: none;
      border: none;
      background: #161a1c;
      border-radius: 4px;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4a5053;
      line-height: .35rem;
      text-indent: .16rem;
      padding-top: .12rem;
      margin-top: .1rem;
    }
  }
  .enclosure {
	  width: 6.56rem;
    // display: flex;
    p {
      height: .54rem;
      line-height: .54rem;
      margin-top: 0px;
    }
    button {
      // margin-left: 38px;
      // width: 131px;
      height: .54rem;
      font-size: .16rem;
      outline-style: none;
      border: none;
      background: #1061c5;
      border-radius: 4px;
    }
		/deep/ .el-upload-list{
			width: 3rem;
		}
  }
}
.btn {
  margin-top: .52rem;
  // margin-left: 90px;
  button {
    width: 1.31rem;
    height: .54rem;
    border: none;
    outline-style: none;
    border-radius: 4px;
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    &:first-of-type {
      background: #307b37;
      color: #ffffff;
      margin-right: .16rem;
    }
    &:last-of-type {
      color: #307b37;
      background: #222628;
      border: 1px solid #307b37;
    }
  }
  .question .enclosure button{
    width: auto;
  }
}
/deep/ .bigimg{
	.el-dialog__header{
		display: none;
	}
	.el-dialog__body{
		padding: .15rem;
	}
	.bigimgsa{
		width: 100%;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		img{
			max-width: 100%;
		}
	}
}
</style>
